<template>
  <section id="concentracao-instrucoes">
    <b-row>
      <b-col md="12">
        <b-card-actions
          ref="loadingCard"
          no-actions
        >
          <div class="text-center">
            <h5>
              Pressione o botão <code>F11</code> para responder o jogo em tela
              cheia.
            </h5>
            <b-card-title class="mt-2">
              Orientação
            </b-card-title>
            <b-card-title class="mt-2">
              Jogo de memória
            </b-card-title>
          </div>
          <b-card-text class="mt-2 text-center">
            O jogo de memória é composto por 4 grandes botões de cores verde
            amarelo, vermelho e azul, que acendem em uma sequência aleatória que
            vai se tornando mais longa a cada rodada.
          </b-card-text>

          <b-card-text class="mt-2 text-center">
            O jogador deve repetir a sequência pressionando os botões na ordem
            correta.
          </b-card-text>

          <b-card-text class="mt-2 text-center">
            O tempo disponível para você concluir o jogo é de
            <code>{{ formato }}</code>.
          </b-card-text>

          <b-card-text class="mt-2 text-center">
            Você terá <code>{{ jogadas }}</code> chances
          </b-card-text>

          <!-- <b-card-text class="mt-2 text-center">
            Boa sorte!
          </b-card-text> -->

          <b-card-text class="mt-2 text-center">
            <div class="mt-2 text-center">
              <b-button
                variant="primary"
                @click="linkMemoria"
              >
                Ir para o jogo de memória
              </b-button>
            </div>
          </b-card-text>
        </b-card-actions>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
import {
  BRow,
  BCol,
  BCardText,
  BAlert,
  BCardTitle,
  BButton
} from "bootstrap-vue"
import useJwt from "@/auth/jwt/useJwt"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  data() {
    return {
      tempo: null,
      minutos: 0,
      segundos: 0,
      formato: "",
      jogadas: 0,
      consultarGame: {
        idDoGame: 0,
        quantidadeDeJogadas: 0,
        tempoMaximo: 0,
        quantidadeDeTeste: 0
      }
    }
  },
  beforeMount() {
    this.consultaMemoria()
  },
  mounted() {
    this.$refs["loadingCard"].showLoading = true // Habilita o loading
  },
  methods: {
    linkMemoria() {
      this.$router.push({ name: "memoria" })
    },

    async consultaMemoria() {
      await useJwt
        .consultaMemoria()
        .catch(error => {
          // Insere tempo no data
          console.error("Não foi possível consultar: ", error.toString())
          // Trata tempo para minutos e segundos
          // Desabilita o loading
          this.$refs["loadingCard"].showLoading = false
        })
        .then(response => {
          this.consultarGame = response.data.dados.consultarGame
          this.jogadas = this.consultarGame.quantidadeDeJogadas
          this.convertTempo()
          this.$refs["loadingCard"].showLoading = false
        })
    },
    convertTempo() {
      this.timer = this.consultarGame.tempoMaximo

      this.minutos = parseInt(this.timer / 60, 10)
      this.segundos = parseInt(this.timer % 60, 10)

      this.formato =
        (this.minutos < 10 ? "0" + this.minutos : this.minutos) +
        ":" +
        (this.segundos < 10 ? "0" + this.segundos : this.segundos)

      return this.formato
    }
  }
}
</script>
